import i18n from 'utils/i18n'
import {
  filterTypes,
  CHECKBOX_LIST,
  MULTISELECT_SWITCHER,
  RADIO_LIST,
  SCORE,
  INTERVAL,
  PRICE_INTERVAL,
  SWITCHER,
  NUMBER,
  RRP_INTERVAL,
  RHEOSTAT,
  ADMIN_ROUTE_NAMES,
  PRODUCT_RANK,
  getAdministrationFiltersCollection,
  getAdministrationRequestsFiltersCollection,
  getAdministrationRequestsPotentialProducersFiltersCollection,
  getAdministrationCompaniesFilterCollection,
  getAdministrationElabelsFilterCollection,
  getAdministrationUsersFiltersCollection,
  getAdministrationTradeOffersFiltersCollection,
  getAdministrationPriceSourcesFiltersCollection,
  getAdministrationEShopsFiltersCollection,
  getDatabaseFiltersCollection,
  RETAIL_ROUTE_NAMES,
  getRetailEShopsFiltersCollection,
} from 'utils/consts'
import { getBuyWineAnalysisRatingFiltersCollection } from 'utils/consts/buyWineAnalysisRatingFilters'
import { getBuyWineOffersFiltersCollection } from './buyWineOffersFilters'
import { sellWineMyOffersFiltersCollection } from './sellWineMyOffersFilters'
import { getBuyWineWinesFiltersCollection } from './buyWineWinesFilters'

export const buyWineOffersTableName = 'buyWineOffers'
export const sellWineOffersTableName = 'sellWineOffers'

export const offersFiltersName = {
  specialClassificationFilter: 'specialClassificationFilter',
  producerFilter: 'producerFilter',
  brandFilter: 'brandFilter',
  countryFilter: 'countryFilter',
  wineTypeFilter: 'wineTypeFilter',
  wineColourFilter: 'wineColourFilter',
  sweetnessTypeFilter: 'sweetnessTypeFilter',
  vintageFilter: 'vintageFilter',
  bottleSizeFilter: 'bottleSizeFilter',
  packageTypeFilter: 'packageTypeFilter',
  grapesFilter: 'grapesFilter',
  certificatesFilter: 'certificatesFilter',
  alcoholLevelFilter: 'alcoholLevelFilter',
  wwlRatingFilter: 'wwlRatingFilter',
  deliveryTermsFilter: 'deliveryTermsFilter',
  countryOfShipmentFilter: 'countryOfShipmentFilter',
  minimalBatchFilter: 'minimalBatchFilter',
  exclusiveOffersFilter: 'exclusiveOffersFilter',
  onlyFromProducerFilter: 'onlyFromProducerFilter',
  forCompanyOnlyFilter: 'forCompanyOnlyFilter',
  onlyCompanyFilter: 'onlyCompanyFilter',
  statusFilter: 'statusFilter',
  euGITypeFilter: 'euGITypeFilter',
  nationalGITypeFilter: 'nationalGITypeFilter',
  wineRegionEUFilter: 'wineRegionEUFilter',
  geoIndicationFilter: 'geoIndicationFilter',
  priceFilter: 'priceFilter',
  pricePerLotFilter: 'pricePerLotFilter',
  pricePerBottleFilter: 'pricePerBottleFilter',
  distributionFilter: 'distributionFilter',
  exhibitionFilter: 'exhibitionFilter',
  monocepageFilter: 'monocepageFilter',
  onlyWithOffersFilter: 'onlyWithOffersFilter',
  rrpFilter: 'rrpFilter',
  priceCoefFilter: 'priceCoefFilter',
  rankFilter: 'rankFilter',
  wineOfferTypeDealFilter: 'wineOfferTypeDealFilter',
  buyerCompanyCategoryFilter: 'buyerCompanyCategoryFilter',
  buyerCompanyCountryFilter: 'buyerCompanyCountryFilter',
  sellerCompanyCategoryFilter: 'sellerCompanyCategoryFilter',
  periodOfValidityFilter: 'periodOfValidityFilter',
  firstPublishedAtFilter: 'firstPublishedAt',
  updatedAtFilter: 'updatedAtFilter',
  sellerCompanyUsernameFilter: 'sellerCompanyUsernameFilter',
  buyerCompanyUsernameFilter: 'buyerCompanyUsernameFilter',
  wineOfferTypeFilter: 'wineOfferTypeFilter',
  producerCountryFilter: 'producerCountryFilter',
  emailStatusFilter: 'emailStatusFilter',
  offersCountryFilter: 'producerCountryFilter',
  offersOnlyForMyCompanyFilter: 'offersOnlyForMyCompanyFilter',
  withEmailFilter: 'withEmailFilter',
  daysSinceLastNewsletterFilter: 'daysSinceLastNewsletterFilter',
  stageFilter: 'stageFilter',
  retailPriceFilter: 'retailPriceFilter',
  showLatestVintageFilter: 'showLatestVintageFilter',
  fromShortlistFilter: 'fromShortlistFilter',
  buyerCompanyFilter: 'buyerCompanyFilter',
  hasOffersFilter: 'hasOffersFilter',
  cellarDoorPriceFilter: 'cellarDoorPriceFilter',
  quantityOfItemFilter: 'quantityOfItemFilter',
  marginFilter: 'marginFilter',
  bestOfferPriceFilter: 'bestOfferFilter',
  coefFilter: 'coefFilter',
  minRetailPriceFilter: 'minRetailPriceFilter',
  recommendedRetailPriceFilter: 'rrpFilter',
  hasBottleImageFilter: 'hasBottleImageFilter',
  hasFrontLabelImageFilter: 'hasFrontLabelImageFilter',
  hasBackLabelImageFilter: 'hasBackLabelImageFilter',
  hasVerifiedRepresentativeFilter: 'hasVerifiedRepresentativeFilter',
  havePublicReviewsFilter: 'havePublicReviewsFilter',
  eShopCountryFilter: 'eShopCountryFilter',
  eShopFilter: 'eShopFilter',
}
export const offersFiltersTitle = {
  countryFilter: 'filters.names.wineCountryFilter',
  wineTypeFilter: 'filters.names.wineTypeFilter',
  wineColourFilter: 'filters.names.wineColourFilter',
  wineCountryFilter: 'filters.names.wineCountryFilter',
  sweetnessTypeFilter: 'filters.names.sweetnessTypeFilter',
  vintageFilter: 'filters.names.vintageFilter',
  bottleSizeFilter: 'filters.names.bottleSizeFilter',
  packageTypeFilter: 'filters.names.packageTypeFilter',
  grapesFilter: 'filters.names.grapesFilter',
  certificatesFilter: 'filters.names.certificatesFilter',
  alcoholLevelFilter: 'filters.names.alcoholLevelFilter',
  wwlRatingFilter: 'filters.names.wwlRatingFilter',
  deliveryTermsFilter: 'filters.names.deliveryTermsFilter',
  countryOfShipmentFilter: 'filters.names.countryOfShipmentFilter',
  minimalBatchFilter: 'filters.names.minimalBatchFilter',
  exclusiveOffersFilter: 'filters.names.exclusiveOffersFilter',
  onlyFromProducerFilter: 'filters.names.onlyFromProducerFilter',
  forCompanyOnlyFilter: 'filters.names.forCompanyOnlyFilter',
  onlyCompanyFilter: 'filters.names.onlyCompanyFilter',
  statusFilter: 'filters.names.statusFilter',
  euGITypeFilter: 'filters.names.euGITypeFilter',
  nationalGITypeFilter: 'filters.names.nationalGITypeFilter',
  wineRegionEUFilter: 'filters.names.wineRegionEUFilter',
  geoIndicationFilter: 'filters.names.geoIndicationFilter',
  priceFilter: 'filters.names.pricePerBottleFilter',
  pricePerLotFilter: 'filters.names.pricePerLotFilter',
  distributionFilter: 'filters.names.distributionFilter',
  exhibitionFilter: 'filters.names.exhibitionFilter',
  producerCountryFilter: 'filters.names.producerCountryFilter',
  emailStatusFilter: 'filters.names.emailState',
  producerFilter: 'filters.names.producer',
  brandFilter: 'filters.names.brandFilter',
  specialClassificationFilter: 'filters.names.specialClassificationFilter',
  monocepageFilter: 'filters.names.monocepageFilter',
  onlyWithOffersFilter: 'filters.names.onlyWithOffersFilter',
  rrpFilter: 'filters.names.rrp',
  priceCoefFilter: 'filters.names.priceCoef',
  rankFilter: 'filters.names.productRank',
  wineOfferTypeFilter: 'filters.names.wineOfferType',
  buyerCompanyCategoryFilter: 'filters.names.buyerCompanyCategory',
  buyerCompanyCountryFilter: 'filters.names.buyerCompanyCountry',
  sellerCompanyCategoryFilter: 'filters.names.sellerCompanyCategory',
  periodOfValidityFilter: 'filters.names.periodOfValidity',
  firstPublishedAtFilter: 'filters.names.firstPublishedAt',
  updatedAtFilter: 'filters.names.updatedAt',
  sellerCompanyUsernameFilter: 'filters.names.sellerCompanyUsername',
  buyerCompanyUsernameFilter: 'filters.names.buyerCompanyUsername',
  offersOnlyForMyCompanyFilter: 'filters.names.onlyForMyCompFilter',
  withEmailFilter: 'filters.names.withEmail',
  daysSinceLastNewsletterFilter: 'filters.names.noActivity',
  stageFilter: 'filters.names.stage',
  retailPriceFilter: 'filters.names.retailPrice',
  forCompanyFilter: 'filters.names.forCompanyFilter',
  showLatestVintageFilter: 'filters.names.showLatestVintage',
  fromShortlistFilter: 'filters.names.fromShortlist',
  buyerCompanyFilter: 'filters.names.buyerCompany',
  hasOffersFilter: 'filters.names.hasOffers',
  cellarDoorPriceFilter: 'filters.names.recommendedRetailPriceFilter',
  quantityOfItemFilter: 'filters.names.quantityOfItem',
  marginFilter: 'filters.names.margin',
  bestOfferPriceFilter: 'filters.names.bestOffer',
  coefFilter: 'filters.names.coef',
  minRetailPriceFilter: 'filters.names.minRetailPrice',
  recommendedRetailPriceFilter: 'filters.names.recommendedRetailPrice',
  hasBottleImageFilter: 'filters.names.packshot',
  hasFrontLabelImageFilter: 'filters.names.frontLabel',
  hasBackLabelImageFilter: 'filters.names.backLabel',
  hasVerifiedRepresentativeFilter: 'filters.names.hasVerifiedRepresentative',
  havePublicReviewsFilter: 'filters.names.havePublicReviewsFilters',
  retailPriceEshopsFilter: 'filters.names.retailPriceEshops',
  eShopCountryFilter: 'filters.names.eShopCountry',
  eShopFilter: 'filters.names.eShop',
  rrpShortNameFilter: 'filters.names.rrpShortNameFilter',
}
export const offersFiltersKey = () => {
  let priceFilter = 'pricePerBottle'

  switch (window.location.pathname.split('/')[2]) {
    case 'buy-wine':
      priceFilter = 'pricePerBottle'
      break
    case 'sell-wine':
      priceFilter = 'price'
      break

    default:
      break
  }

  return {
    countryFilter: 'product.wine.country',
    wineTypeFilter: 'product.wine.wineType',
    wineColourFilter: 'product.wine.wineColor',
    sweetnessTypeFilter: 'product.wine.sweetnessLevel',
    vintageFilter: 'product.wine.vintage',
    bottleSizeFilter: 'bottleSize',
    packageTypeFilter: 'packageType',
    grapesFilter: 'grapeAndMonocepage',
    certificatesFilter: 'product.wine.certification',
    alcoholLevelFilter: 'product.alcoholOnLabel',
    wwlRatingFilter: 'product.wine.rating',
    deliveryTermsFilter: 'incoterms',
    countryOfShipmentFilter: 'countryOfShipment',
    minimalBatchFilter: 'minimumDeliverySize',
    daysSinceLastNewsletterFilter: 'daysSinceLastNewsletter',
    exclusiveOffersFilter: 'exclusive',
    onlyFromProducerFilter: 'onlyFromProducer',
    forCompanyOnlyFilter: 'onlyForMe',
    onlyCompanyFilter: 'onlyCompany',
    statusFilter: 'wineOfferStatus',
    euGITypeFilter: 'product.wine.nationalGIType.euGIType',
    nationalGITypeFilter: 'product.wine.nationalGIType',
    wineRegionEUFilter: 'product.wine.wineRegionEU',
    geoIndicationFilter: 'product.wine.geoIndication',
    priceFilter: priceFilter,
    distributionFilter: 'countryOfDelivery',
    exhibitionFilter: 'exhibition',
    producerCountry: 'product.wine.tradeName.country',
    producerFilter: 'product.wine.tradeName',
    brandFilter: 'product.wine.brand',
    specialClassificationFilter: 'product.wine.specialClassification',
    monocepageFilter: 'grapeAndMonocepage.monocepage',
    rrpFilter: 'product.priceAtWinery',
    priceCoefFilter: 'priceCoefficient',
    rankFilter: 'product.appellationRating.rank',
    wineOfferTypeDealFilter: 'wineOfferTypeDeal',
    sellerCompanyCategoryFilter: 'sellerCompanyCategory',
    buyerCompanyCategoryFilter: 'buyerCompanyCategory',
    buyerCompanyCountryFilter: 'buyerCompanyCountry',
    periodOfValidityFilter: 'periodOfValidity',
    firstPublishedAtFilter: 'firstPublishedAt',
    updatedAtFilter: 'updatedAt',
    sellerCompanyUsernameFilter: 'sellerCompanyUsername',
    buyerCompanyUsernameFilter: 'buyerCompanyUsername',
    buyerCompanyFilter: 'buyerCompanyNameUsernameSecured',
    wineOfferTypeFilter: 'wineOfferType',
    onlyWithOffersFilter: 'onlyWithOffers',
    offersCountryFilter: 'wine.country',
    offersMonocepageFilter: 'wine.monocepage',
    offersProducerFilter: 'wine.tradeName',
    offersBrandFilter: 'wine.brand',
    offersSpecialClassification: 'wine.specialClassification',
    offersVintageFilter: 'wine.vintage',
    offersGrapesFilter: 'wine.grapeVarieties',
    offersCertificatesFilter: 'wine.certification',
    offersAlcoholLevelFilter: 'alcoholOnLabel',
    offersWwlRatingFilter: 'wine.rating',
    offersRrpFilter: 'priceAtWinery',
    offersRankFilter: 'appellationRating.rank',
    offersPriceFilter: 'wineOffers.pricePerBottle',
    offersDeliveryTermsFilter: 'wineOffers.incoterms',
    offersCountryOfShipmentFilter: 'wineOffers.countryOfShipment',
    offersPriceCoefFilter: 'wineOffers.priceCoefficient',
    offersExhibitionFilter: 'wineOffers.exhibition',
    offersMinimalBatchFilter: 'wineOffers.minimumDeliverySize',
    offersOnlyForMyCompanyFilter: 'wineOffers.onlyForMe',
    withEmailFilter: 'onlyWithEmail',
    emailStatus: 'emailStatus',
    stageFilter: 'stage',
    retailPriceFilter: 'tradeOfferLotPricePerBottle',
    showLatestVintageFilter: 'onlyLatestVintage',
    fromShortlistFilter: 'onlyFromCompanyProductShortlist',
    hasOffersFilter: 'hasOffers',
    cellarDoorPriceFilter: 'cellarDoorPrice',
    quantityOfItemFilter: 'activeTradeOfferCount',
  }
}
export const NO_RESTRICTIONS = '#NO_RESTRICTIONS'

export const BUY_WINE_ANALYSIS = 'accBuyWineAnalysis'

export const OFFERS_ROUTE_NAMES = {
  buyWineOffers: 'accBuyWineOffers',
  sellWineMyOffers: 'accSellWineMyOffers',
  buyWineAnalysis: 'accBuyWineAnalysis',
  buyWineAnalysisRating: 'accBuyWineAnalysisRating',
  buyWineWines: 'accBuyWineWines',
}

export const excludedFiltersOnRoute = {
  [OFFERS_ROUTE_NAMES.buyWineOffers]: [
    offersFiltersName.euGITypeFilter,
    offersFiltersName.distributionFilter,
    offersFiltersName.rankFilter,
    offersFiltersName.monocepageFilter,
    offersFiltersName.buyerCompanyCountryFilter,
  ],
  [OFFERS_ROUTE_NAMES.buyWineAnalysis]: [
    offersFiltersName.distributionFilter,
    offersFiltersName.monocepageFilter,
  ],
  [ADMIN_ROUTE_NAMES.adminOffers]: [],
  [ADMIN_ROUTE_NAMES.adminRequests]: [],
  [OFFERS_ROUTE_NAMES.sellWineMyOffers]: [
    offersFiltersName.euGITypeFilter,
    offersFiltersName.onlyFromProducerFilter,
    offersFiltersName.forCompanyOnlyFilter,
    offersFiltersName.rrpFilter,
    offersFiltersName.priceCoefFilter,
    offersFiltersName.rankFilter,
    offersFiltersName.monocepageFilter,
  ],
}

export const getOffersFiltersCollection = (storeName?: string) => {
  if (storeName === 'accBuyWineAnalysis') {
    return [
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.producerFilter),
        filterName: offersFiltersName.producerFilter,
        apiFilterName: offersFiltersKey().producerFilter,
      },
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.brandFilter),
        filterName: offersFiltersName.brandFilter,
        apiFilterName: offersFiltersKey().brandFilter,
      },
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.countryFilter),
        filterName: offersFiltersName.countryFilter,
        apiFilterName: offersFiltersKey().countryFilter,
      },
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.euGITypeFilter),
        filterName: offersFiltersName.euGITypeFilter,
        apiFilterName: offersFiltersKey().euGITypeFilter,
      },
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.nationalGITypeFilter),
        filterName: offersFiltersName.nationalGITypeFilter,
        apiFilterName: offersFiltersKey().nationalGITypeFilter,
      },
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.wineRegionEUFilter),
        filterName: offersFiltersName.wineRegionEUFilter,
        apiFilterName: offersFiltersKey().wineRegionEUFilter,
      },
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.geoIndicationFilter),
        filterName: offersFiltersName.geoIndicationFilter,
        apiFilterName: offersFiltersKey().geoIndicationFilter,
      },
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.specialClassificationFilter),
        filterName: offersFiltersName.specialClassificationFilter,
        apiFilterName: offersFiltersKey().specialClassificationFilter,
      },
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.wineTypeFilter),
        filterName: offersFiltersName.wineTypeFilter,
        apiFilterName: offersFiltersKey().wineTypeFilter,
      },
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.wineColourFilter),
        filterName: offersFiltersName.wineColourFilter,
        apiFilterName: offersFiltersKey().wineColourFilter,
      },
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.sweetnessTypeFilter),
        filterName: offersFiltersName.sweetnessTypeFilter,
        apiFilterName: offersFiltersKey().sweetnessTypeFilter,
      },
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.vintageFilter),
        filterName: offersFiltersName.vintageFilter,
        apiFilterName: offersFiltersKey().vintageFilter,
      },
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.bottleSizeFilter),
        filterName: offersFiltersName.bottleSizeFilter,
        apiFilterName: offersFiltersKey().bottleSizeFilter,
      },
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.packageTypeFilter),
        filterName: offersFiltersName.packageTypeFilter,
        apiFilterName: offersFiltersKey().packageTypeFilter,
      },
      {
        type: filterTypes[MULTISELECT_SWITCHER],
        title: i18n.t(offersFiltersTitle.grapesFilter),
        filterName: offersFiltersName.grapesFilter,
        apiFilterName: offersFiltersKey().grapesFilter,
        switchType: filterTypes[SWITCHER],
        switchFilterName: offersFiltersName.monocepageFilter,
        switchTitle: i18n.t(offersFiltersTitle.monocepageFilter),
        switchApiFilterName: offersFiltersKey().monocepageFilter,
      },
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.certificatesFilter),
        filterName: offersFiltersName.certificatesFilter,
        apiFilterName: offersFiltersKey().certificatesFilter,
      },
      {
        type: filterTypes[INTERVAL],
        title: i18n.t(offersFiltersTitle.alcoholLevelFilter),
        filterName: offersFiltersName.alcoholLevelFilter,
        apiFilterName: offersFiltersKey().alcoholLevelFilter,
      },
      {
        type: filterTypes[SCORE],
        title: i18n.t(offersFiltersTitle.wwlRatingFilter),
        filterName: offersFiltersName.wwlRatingFilter,
        apiFilterName: offersFiltersKey().wwlRatingFilter,
      },
      {
        type: filterTypes[RRP_INTERVAL],
        title: i18n.t(offersFiltersTitle.rrpFilter),
        filterName: offersFiltersName.rrpFilter,
        apiFilterName: offersFiltersKey().rrpFilter,
      },

      {
        type: filterTypes[PRICE_INTERVAL],
        title: i18n.t(offersFiltersTitle.priceFilter),
        filterName: offersFiltersName.priceFilter,
        apiFilterName: offersFiltersKey().priceFilter,
      },
      {
        type: filterTypes[NUMBER],
        title: i18n.t(offersFiltersTitle.minimalBatchFilter),
        filterName: offersFiltersName.minimalBatchFilter,
        apiFilterName: offersFiltersKey().minimalBatchFilter,
      },
      {
        type: filterTypes[RADIO_LIST],
        title: i18n.t(offersFiltersTitle.deliveryTermsFilter),
        filterName: offersFiltersName.deliveryTermsFilter,
        apiFilterName: offersFiltersKey().deliveryTermsFilter,
      },
      {
        type: filterTypes[RADIO_LIST],
        title: i18n.t(offersFiltersTitle.countryOfShipmentFilter),
        filterName: offersFiltersName.countryOfShipmentFilter,
        apiFilterName: offersFiltersKey().countryOfShipmentFilter,
      },
      {
        type: filterTypes[PRODUCT_RANK],
        title: i18n.t(offersFiltersTitle.rankFilter),
        filterName: offersFiltersName.rankFilter,
        apiFilterName: offersFiltersKey().rankFilter,
      },
      {
        type: filterTypes[RHEOSTAT],
        title: i18n.t(offersFiltersTitle.priceCoefFilter),
        filterName: offersFiltersName.priceCoefFilter,
        apiFilterName: offersFiltersKey().priceCoefFilter,
      },
      {
        type: filterTypes[CHECKBOX_LIST],
        title: i18n.t(offersFiltersTitle.wineOfferTypeFilter),
        filterName: offersFiltersName.wineOfferTypeDealFilter,
        apiFilterName: offersFiltersKey().wineOfferTypeDealFilter,
      },
    ]
  }

  if (storeName === ADMIN_ROUTE_NAMES.adminOffers) {
    return getAdministrationFiltersCollection()
  }
  if (storeName === 'accBuyWineWines') {
    return getBuyWineWinesFiltersCollection()
  }
  if (
    storeName === 'accDatabaseProducers' ||
    storeName === 'accDatabaseWines'
  ) {
    return getDatabaseFiltersCollection()
  }
  if (storeName === ADMIN_ROUTE_NAMES.adminElabels) {
    return getAdministrationElabelsFilterCollection()
  }
  if (storeName === ADMIN_ROUTE_NAMES.adminCompanies) {
    return getAdministrationCompaniesFilterCollection()
  }
  if (storeName === ADMIN_ROUTE_NAMES.adminRequests) {
    return getAdministrationRequestsFiltersCollection()
  }
  if (storeName === ADMIN_ROUTE_NAMES.potentialProducers) {
    return getAdministrationRequestsPotentialProducersFiltersCollection()
  }
  if (storeName === ADMIN_ROUTE_NAMES.adminUsers) {
    return getAdministrationUsersFiltersCollection()
  }
  if (storeName === ADMIN_ROUTE_NAMES.adminTradeOffers) {
    return getAdministrationTradeOffersFiltersCollection()
  }
  if (storeName === ADMIN_ROUTE_NAMES.adminPriceSources) {
    return getAdministrationPriceSourcesFiltersCollection()
  }
  if (storeName === ADMIN_ROUTE_NAMES.adminMerchants) {
    return getAdministrationEShopsFiltersCollection()
  }
  if (storeName === 'accBuyWineAnalysisRating') {
    return getBuyWineAnalysisRatingFiltersCollection()
  }
  if (storeName === 'accBuyWineOffers') {
    return getBuyWineOffersFiltersCollection()
  }
  if (storeName === 'accSellWineMyOffers') {
    return sellWineMyOffersFiltersCollection()
  }
  if (storeName === RETAIL_ROUTE_NAMES.retailEShops) {
    return getRetailEShopsFiltersCollection()
  }
  return [
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.producerFilter),
      filterName: offersFiltersName.producerFilter,
      apiFilterName: offersFiltersKey().producerFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.brandFilter),
      filterName: offersFiltersName.brandFilter,
      apiFilterName: offersFiltersKey().brandFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.countryFilter),
      filterName: offersFiltersName.countryFilter,
      apiFilterName: offersFiltersKey().countryFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.euGITypeFilter),
      filterName: offersFiltersName.euGITypeFilter,
      apiFilterName: offersFiltersKey().euGITypeFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.nationalGITypeFilter),
      filterName: offersFiltersName.nationalGITypeFilter,
      apiFilterName: offersFiltersKey().nationalGITypeFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.wineRegionEUFilter),
      filterName: offersFiltersName.wineRegionEUFilter,
      apiFilterName: offersFiltersKey().wineRegionEUFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.geoIndicationFilter),
      filterName: offersFiltersName.geoIndicationFilter,
      apiFilterName: offersFiltersKey().geoIndicationFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.specialClassificationFilter),
      filterName: offersFiltersName.specialClassificationFilter,
      apiFilterName: offersFiltersKey().specialClassificationFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.wineTypeFilter),
      filterName: offersFiltersName.wineTypeFilter,
      apiFilterName: offersFiltersKey().wineTypeFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.wineColourFilter),
      filterName: offersFiltersName.wineColourFilter,
      apiFilterName: offersFiltersKey().wineColourFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.sweetnessTypeFilter),
      filterName: offersFiltersName.sweetnessTypeFilter,
      apiFilterName: offersFiltersKey().sweetnessTypeFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.vintageFilter),
      filterName: offersFiltersName.vintageFilter,
      apiFilterName: offersFiltersKey().vintageFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.bottleSizeFilter),
      filterName: offersFiltersName.bottleSizeFilter,
      apiFilterName: offersFiltersKey().bottleSizeFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.packageTypeFilter),
      filterName: offersFiltersName.packageTypeFilter,
      apiFilterName: offersFiltersKey().packageTypeFilter,
    },
    {
      type: filterTypes[MULTISELECT_SWITCHER],
      title: i18n.t(offersFiltersTitle.grapesFilter),
      filterName: offersFiltersName.grapesFilter,
      apiFilterName: offersFiltersKey().grapesFilter,
      switchType: filterTypes[SWITCHER],
      switchFilterName: offersFiltersName.monocepageFilter,
      switchTitle: i18n.t(offersFiltersTitle.monocepageFilter),
      switchApiFilterName: offersFiltersKey().monocepageFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.certificatesFilter),
      filterName: offersFiltersName.certificatesFilter,
      apiFilterName: offersFiltersKey().certificatesFilter,
    },
    {
      type: filterTypes[INTERVAL],
      title: i18n.t(offersFiltersTitle.alcoholLevelFilter),
      filterName: offersFiltersName.alcoholLevelFilter,
      apiFilterName: offersFiltersKey().alcoholLevelFilter,
    },
    {
      type: filterTypes[SCORE],
      title: i18n.t(offersFiltersTitle.wwlRatingFilter),
      filterName: offersFiltersName.wwlRatingFilter,
      apiFilterName: offersFiltersKey().wwlRatingFilter,
    },
    {
      type: filterTypes[PRICE_INTERVAL],
      title: i18n.t(offersFiltersTitle.priceFilter),
      filterName: offersFiltersName.priceFilter,
      apiFilterName: offersFiltersKey().priceFilter,
    },
    {
      type: filterTypes[NUMBER],
      title: i18n.t(offersFiltersTitle.minimalBatchFilter),
      filterName: offersFiltersName.minimalBatchFilter,
      apiFilterName: offersFiltersKey().minimalBatchFilter,
    },
    {
      type: filterTypes[RADIO_LIST],
      title: i18n.t(offersFiltersTitle.deliveryTermsFilter),
      filterName: offersFiltersName.deliveryTermsFilter,
      apiFilterName: offersFiltersKey().deliveryTermsFilter,
    },
    {
      type: filterTypes[RADIO_LIST],
      title: i18n.t(offersFiltersTitle.countryOfShipmentFilter),
      filterName: offersFiltersName.countryOfShipmentFilter,
      apiFilterName: offersFiltersKey().countryOfShipmentFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.distributionFilter),
      filterName: offersFiltersName.distributionFilter,
      apiFilterName: offersFiltersKey().distributionFilter,
    },
    {
      type: filterTypes[SWITCHER],
      title: i18n.t(offersFiltersTitle.exclusiveOffersFilter),
      filterName: offersFiltersName.exclusiveOffersFilter,
      apiFilterName: offersFiltersKey().exclusiveOffersFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.exhibitionFilter),
      filterName: offersFiltersName.exhibitionFilter,
      apiFilterName: offersFiltersKey().exhibitionFilter,
    },
    {
      type: filterTypes[SWITCHER],
      title: i18n.t(offersFiltersTitle.onlyFromProducerFilter),
      filterName: offersFiltersName.onlyFromProducerFilter,
      apiFilterName: offersFiltersKey().onlyFromProducerFilter,
    },
    {
      type: filterTypes[SWITCHER],
      title: i18n.t(offersFiltersTitle.forCompanyOnlyFilter),
      filterName: offersFiltersName.forCompanyOnlyFilter,
      apiFilterName: offersFiltersKey().forCompanyOnlyFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.statusFilter),
      filterName: offersFiltersName.statusFilter,
      apiFilterName: offersFiltersKey().statusFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.buyerCompanyCountryFilter),
      filterName: offersFiltersName.buyerCompanyCountryFilter,
      apiFilterName: offersFiltersKey().buyerCompanyCountryFilter,
    },
  ]
}
const flippedTableFiltersKey = () => {
  const flip = obj => {
    const ret = {}
    Object.keys(obj).forEach(key => {
      ret[obj[key]] = key
    })
    return ret
  }
  return flip(offersFiltersKey())
}

export const convertApiFiltersToAppFilters = apiFilters => {
  const filterKeys = flippedTableFiltersKey()
  const convert = filter => {
    const appFilterName = filterKeys[filter?.key]
    const converters = {
      producerCountry: filter => ({ countryFilter: filter?.byName }),
      grapesFilter: filter => {
        return {
          [appFilterName]: {
            list: filter?.childrenInputs?.find(
              input => input?.key === 'grapeAndMonocepage.grape'
            )?.byName,
            switcher: filter?.childrenInputs?.find(
              input => input?.key === 'grapeAndMonocepage.monocepage'
            )?.value,
          },
        }
      },
      deliveryTermsFilter: filter => ({ [appFilterName]: filter?.byName?.[0] }),
      countryOfShipmentFilter: filter => ({
        [appFilterName]: filter?.byName?.[0],
      }),
      minimalBatchFilter: filter => ({
        [appFilterName]: filter?.minValue,
      }),
      priceFilter: filter => {
        const price = filter?.childrenInputs?.find(
          filter => filter?.key === 'pricePerBottle.value'
        )
        const currency = filter?.childrenInputs?.find(
          filter => filter?.key === 'pricePerBottle.currency'
        )
        return {
          [appFilterName]: {
            currency: currency?.byName?.[0],
            interval: {
              minValue: price?.minValue || 0,
              maxValue: price?.maxValue,
            },
          },
        }
      },
      bestOfferPriceFilter: filter => {
        const price = filter?.childrenInputs?.find(
          filter => filter?.key === 'bestOfferPricePerBottle.value'
        )
        const currency = filter?.childrenInputs?.find(
          filter => filter?.key === 'bestOfferPricePerBottle.currency'
        )
        return {
          ['bestOfferFilter']: {
            currency: currency?.byName?.[0],
            interval: {
              minValue: price?.minValue || 0,
              maxValue: price?.maxValue,
            },
          },
        }
      },
      alcoholLevelFilter: filter => ({
        [appFilterName]: {
          minValue: filter?.minValue || 0,
          maxValue: filter?.maxValue,
        },
      }),
      wwlRatingFilter: filter => ({
        [appFilterName]: filter?.byName?.[0],
      }),
      FacetDateRangeFilter: filter => ({
        [appFilterName]: { fromDate: filter?.fromDate, toDate: filter?.toDate },
      }),
      FacetListFilterSelection: filter => {
        return { [appFilterName]: filter?.byName }
      },
      FacetBooleanFilterSelection: filter => {
        return { [appFilterName]: filter?.value }
      },
    }
    // if (!appFilterName) return
    const converter =
      converters[appFilterName] || converters[filter?.__typename]
    if (!converter) return
    return converter(filter)
  }

  return (apiFilters || {}).reduce((acc, filter) => {
    const convertedFilter = convert(filter)
    if (!convertedFilter) return acc
    return {
      ...acc,
      ...convertedFilter,
    }
  }, {})
}
