import i18n from 'utils/i18n'
import {
  filterTypes,
  CHECKBOX_LIST,
  SCORE,
  INTERVAL,
  SWITCHER,
  offersFiltersTitle,
  offersFiltersName,
  MULTISELECT_SWITCHER,
  RRP_INTERVAL,
} from 'utils/consts'

export const RETAIL_ROUTE_NAMES = {
  retailEShops: 'accRetailEShops',
}
export const retailEshopsFiltersKey = {
  wineCountryFilter: 'wineCountry',
  producerFilter: 'tradeName',
  brandFilter: 'brand',
  nationalGITypeFilter: 'nationalGIType',
  geoIndicationFilter: 'geoIndication',
  specialClassificationFilter: 'specialClassification',
  wineTypeFilter: 'wineType',
  wineColourFilter: 'wineColor',
  sweetnessTypeFilter: 'sweetnessLevel',
  vintageFilter: 'vintage',
  grapesFilter: 'grapeAndMonocepage',
  bottleSizeFilter: 'bottleSize',
  packageTypeFilter: 'packageType',
  alcoholLevelFilter: 'chemicalAnalysisAlcohol',
  certificatesFilter: 'certification',
  wwlRatingFilter: 'wines.rating',
  rrpFilter: 'recommendedRetailPrice',
  retailPriceFilter: 'retailPrice',
  monocepageFilter: 'grapeAndMonocepage.monocepage',
  eShopCountryFilter: 'country',
  eShopFilter: 'name',
}

export const getRetailEShopsFiltersCollection = () => [
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.eShopCountryFilter),
    filterName: offersFiltersName.eShopCountryFilter,
    apiFilterName: retailEshopsFiltersKey.eShopCountryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.eShopFilter),
    filterName: offersFiltersName.eShopFilter,
    apiFilterName: retailEshopsFiltersKey.eShopFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineCountryFilter),
    filterName: offersFiltersName.countryFilter,
    apiFilterName: retailEshopsFiltersKey.wineCountryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.producerFilter),
    filterName: offersFiltersName.producerFilter,
    apiFilterName: retailEshopsFiltersKey.producerFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.brandFilter),
    filterName: offersFiltersName.brandFilter,
    apiFilterName: retailEshopsFiltersKey.brandFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.nationalGITypeFilter),
    filterName: offersFiltersName.nationalGITypeFilter,
    apiFilterName: retailEshopsFiltersKey.nationalGITypeFilter,
  },

  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.geoIndicationFilter),
    filterName: offersFiltersName.geoIndicationFilter,
    apiFilterName: retailEshopsFiltersKey.geoIndicationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.specialClassificationFilter),
    filterName: offersFiltersName.specialClassificationFilter,
    apiFilterName: retailEshopsFiltersKey.specialClassificationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineTypeFilter),
    filterName: offersFiltersName.wineTypeFilter,
    apiFilterName: retailEshopsFiltersKey.wineTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineColourFilter),
    filterName: offersFiltersName.wineColourFilter,
    apiFilterName: retailEshopsFiltersKey.wineColourFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.sweetnessTypeFilter),
    filterName: offersFiltersName.sweetnessTypeFilter,
    apiFilterName: retailEshopsFiltersKey.sweetnessTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.vintageFilter),
    filterName: offersFiltersName.vintageFilter,
    apiFilterName: retailEshopsFiltersKey.vintageFilter,
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(offersFiltersTitle.retailPriceEshopsFilter),
    filterName: offersFiltersName.retailPriceFilter,
    apiFilterName: retailEshopsFiltersKey.retailPriceFilter,
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(offersFiltersTitle.rrpShortNameFilter),
    filterName: offersFiltersName.rrpFilter,
    apiFilterName: retailEshopsFiltersKey.rrpFilter,
  },
  {
    type: filterTypes[SCORE],
    title: i18n.t(offersFiltersTitle.wwlRatingFilter),
    filterName: offersFiltersName.wwlRatingFilter,
    apiFilterName: retailEshopsFiltersKey.wwlRatingFilter,
  },
  {
    type: filterTypes[MULTISELECT_SWITCHER],
    title: i18n.t(offersFiltersTitle.grapesFilter),
    filterName: offersFiltersName.grapesFilter,
    apiFilterName: retailEshopsFiltersKey.grapesFilter,
    switchType: filterTypes[SWITCHER],
    switchFilterName: offersFiltersName.monocepageFilter,
    switchTitle: i18n.t(offersFiltersTitle.monocepageFilter),
    switchApiFilterName: retailEshopsFiltersKey.monocepageFilter,
  },
  {
    type: filterTypes[INTERVAL],
    title: i18n.t(offersFiltersTitle.alcoholLevelFilter),
    filterName: offersFiltersName.alcoholLevelFilter,
    apiFilterName: retailEshopsFiltersKey.alcoholLevelFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.certificatesFilter),
    filterName: offersFiltersName.certificatesFilter,
    apiFilterName: retailEshopsFiltersKey.certificatesFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.bottleSizeFilter),
    filterName: offersFiltersName.bottleSizeFilter,
    apiFilterName: retailEshopsFiltersKey.bottleSizeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.packageTypeFilter),
    filterName: offersFiltersName.packageTypeFilter,
    apiFilterName: retailEshopsFiltersKey.packageTypeFilter,
  },
]
