import TableContext from '../index'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useScreen from 'utils/screen'
import { breakpoint } from 'utils/consts/breakpoints'
import randomString from 'random-string'

type Container = keyof typeof breakpoint
type ContainersMap = Container[]
type ElementToHide = HTMLElement & {
  __initialHeight: number
  __containerOnly: ContainersMap
  __uniqueId: string
}
export type RefCallbackOptions = {
  containerOnly?: ContainersMap
}

let timeoutId
let isFirstDetection = true
let lastScrollTop = 0
let isHeadersVisible = true
const GAP = 50

const detectHeightChange = (entries: ResizeObserverEntry[]) => {
  if (isFirstDetection) {
    isFirstDetection = false
    return
  }
  entries.forEach(entry => {
    const el = entry.target as ElementToHide
    el.__initialHeight = entry.contentRect.height
  })
}
const resizeObserver = new ResizeObserver(detectHeightChange)

const TableContextProvider = ({ children }) => {
  const [reactTableProps, setReactTableProps] = useState()
  const [tableContentRef, _setTableContentRef] = useState<HTMLElement | null>(
    null
  )
  const [elementsToHide, _setCollapsibleHeader] = useState<ElementToHide[]>([])
  // @ts-ignore
  const { currentContainer }: { currentContainer: Container } = useScreen()

  const setTableContentRef = useCallback(node => {
    if (node !== null) {
      _setTableContentRef(node)
    }
  }, [])
  const setCollapsibleHeader = useCallback(
    (_node: HTMLElement | null, options: RefCallbackOptions = {}) => {
      const node = _node as ElementToHide
      if (node === null) return
      if (
        node.__uniqueId &&
        elementsToHide.some(el => el.__uniqueId === node.__uniqueId)
      ) {
        return
      }

      if (options.containerOnly) {
        node.__containerOnly = options.containerOnly
      }
      node.__uniqueId = randomString({ length: 10 })

      _setCollapsibleHeader(prev => [...prev, node as ElementToHide])
    },
    [elementsToHide]
  )

  const handleTableScroll = e => {
    const scrollTop = e.currentTarget.scrollTop
    const elements = elementsToHide.filter(node => {
      return (
        !node.__containerOnly || node.__containerOnly.includes(currentContainer)
      )
    })
    const heightToStarAnimation = elements.reduce((acc, el) => {
      return acc + el.__initialHeight
    }, GAP)
    const isScrollDown = scrollTop > lastScrollTop

    resizeObserver.disconnect()
    clearTimeout(timeoutId)
    isFirstDetection = true

    if (isScrollDown) {
      if (heightToStarAnimation > scrollTop || !isHeadersVisible) return
      isHeadersVisible = false
      elements.forEach(node => {
        const newHeight = Math.max(node.__initialHeight - scrollTop, 0)
        node.style.height = `${newHeight}px`
        node.style.overflow = `hidden`
      })
    } else {
      if (heightToStarAnimation < scrollTop || isHeadersVisible) return
      isHeadersVisible = true
      elements.forEach(node => {
        node.style.height = `${node.__initialHeight}px`
        node.addEventListener(
          'transitionend',
          () => {
            node.style.overflow = `visible`
          },
          { once: true }
        )
      })
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop

    timeoutId = setTimeout(() => {
      elementsToHide.forEach(node => {
        resizeObserver.observe(node)
      })
    }, 1000)
  }

  useEffect(() => {
    elementsToHide.forEach(node => {
      node.style.height = 'auto'
      node.__initialHeight = node.offsetHeight
    })
  }, [currentContainer])

  useEffect(() => {
    if (tableContentRef) {
      tableContentRef.onscroll = handleTableScroll
    }
  }, [tableContentRef, elementsToHide, currentContainer])

  useEffect(() => {
    elementsToHide.forEach(node => {
      node.__initialHeight = node.offsetHeight
      node.style.height = `${node.offsetHeight}px`
      resizeObserver.observe(node)
    })
  }, [elementsToHide])

  const value = useMemo(
    () => ({
      reactTableProps,
      setReactTableProps,
      setTableContentRef,
      tableContentRef,
      setCollapsibleHeader,
    }),
    [reactTableProps, setTableContentRef, setReactTableProps]
  )

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>
}

export default TableContextProvider
