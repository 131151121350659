export const HOMEPAGE_PATH = '/acc'
export const GETTING_STARTED_PATH = HOMEPAGE_PATH
export const MY_COMPANY_PATH = '/acc/my-company'
export const CREATE_PRODUCER_PATH = '/acc/producer/create'
export const EDIT_PRODUCER_PATH = '/acc/producer/:id/edit'
export const VIEW_PRODUCER_PATH = '/acc/producer/:id'
export const CREATE_BRAND_PATH = '/acc/brand/create'
export const VIEW_BRAND_PATH = '/acc/brand/:id'
export const EDIT_BRAND_PATH = '/acc/brand/:id/edit'
export const VIEW_OFFER_PATH = '/acc/offer/:id'
export const EDIT_OFFER_PATH = '/acc/offer/:id/edit'

export const DATABASE_PATH_PREFIX = '/acc/database'
export const DATABASE_PATH = '/acc/database/:tab(producers|brands|wines)'

export const SELL_WINE_PATH = '/acc/sell-wine'
export const SELL_WINE_MY_OFFERS_PATH =
  '/acc/sell-wine/my-offers/:tab(all|negotiations|accepted|archived)'
export const SELL_WINE_MY_OFFERS_ALL_PATH = '/acc/sell-wine/my-offers/all'

export const BUY_WINE_PATH = '/acc/buy-wine'
export const BUY_WINE_MY_OFFERS_ALL_PATH = '/acc/buy-wine/offers/all'
export const BUY_WINE_MY_OFFERS_PATH =
  '/acc/buy-wine/offers/:tab(all|shortlist|negotiations|my-deals|archived)'
export const BUY_WINE_NEW_REQUEST_PATH = '/acc/buy-wine/new-request'
export const BUY_WINE_MY_REQUESTS_PATH = '/acc/buy-wine/my-requests'
export const BUY_WINE_WINES = '/acc/buy-wine/wines'

export const CREATE_WINE_PATH = '/acc/wine/create'
export const EDIT_WINE_PATH =
  '/acc/wine/:id/edit/:tab(identification|general|packaging|awards|techsheets)?'
export const VIEW_WINE_PATH = '/acc/product/:slug'
export const VIEW_WINE_SLUG_PATH = '/acc/wine/:wine/:product?'
export const WINE_RATING_PATH = '/acc/product/:slug/:geoIndicationId'
export const APPELLATION_RATING_PATH =
  '/acc/buy-wine/analysis/:slug/:geoId/:offerId'
export const CREATE_COMPANY = '/acc/company/create'
export const EDIT_COMPANY = '/acc/company/:id/edit'
export const LOGIN_PAGE_PATH = '/acc/login'
export const SIGNUP_PAGE_PATH = '/acc/signup'
export const PAYMENT_PAGE_PATH = '/acc/payment'

export const ADMIN_PATH = '/acc/administration'
export const ADMIN_IMAGES = '/acc/administration/:tab(images)'
export const ADMINISTRATION_ADD_IMAGES = '/acc/images/create'

export const MY_WINES_PATH = '/acc/my-wines'
export const MY_WINES_ALL_PATH = '/acc/my-wines/wines/all'
export const MY_WINES_SHORTLIST_PATH = '/acc/my-wines/wines/shortlist'
export const MY_WINES_E_LABELS = '/acc/my-wines/e-labels'

export const REVIEWS_PATH = '/acc/reviews'
export const REVIEWS_DASHBOARD = REVIEWS_PATH + '/dashboard'
export const REVIEWS_COMPANY_REVIEWS = REVIEWS_PATH + '/company-reviews'
export const REVIEWS_MY_REVIEWS = REVIEWS_PATH + '/my-reviews'

export const EDIT_REVIEW = '/acc/review/:id/:action(edit)'
export const BUY_REVIEW = '/acc/reviews/company-reviews/request-buy-review'

export const ADMINISTRATION_MERCHANT_CREATE = '/acc/e-shop/create'
export const ADMINISTRATION_MERCHANT_EDIT = '/acc/e-shop/:id/edit'
export const ADMINISTRATION_MERCHANTS = '/acc/administration/e-shops'
export const ADMINISTRATION_TRADE_OFFERS = '/acc/administration/retail-offers'
export const ADMINISTRATION_TRADE_OFFER_CREATE = '/acc/retail-offer/create'
export const ADMINISTRATION_TRADE_OFFER_EDIT = '/acc/retail-offer/:id/edit'
export const ADMINISTRATION_OFFERS = '/acc/administration/offers'

export const ADMINISTRATION_RETAIL_OFFER_PRICE =
  '/acc/retail-offer-price/:id/create'
export const ADMINISTRATION_RETAIL_OFFER_PRICES =
  '/acc/administration/retail-offers/:id/prices'
export const RETAIL = '/acc/retail'
export const RETAIL_ESHOPS = '/acc/retail/e-shops'
