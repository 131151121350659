import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RetailStore, Sorting } from './types'
import { Filters, PageInfo } from './types'

const initialState: RetailStore = {
  items: [],
  pageInfo: null,
  sorting: {
    type: null,
    name: null,
    value: null,
  },
  loading: true,
  error: null,
  query: '',
  filters: [],
}
const retailSlice = createSlice({
  name: 'retail',
  initialState,
  reducers: {
    setItems: (state, { payload }: PayloadAction<any[]>) => {
      state.items = payload
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload
    },
    setSortingValue: (state, { payload }: PayloadAction<Sorting | null>) => {
      state.sorting = payload
    },
    clear: () => {
      return initialState
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
    setQuery: (state, { payload }: PayloadAction<string>) => {
      state.query = payload
      state.pageInfo = {
        ...state.pageInfo,
        page: 1,
      }
    },
    setFilters: (state, { payload }: PayloadAction<Filters>) => {
      state.filters = payload
      state.pageInfo = {
        ...state.pageInfo,
        page: 1,
      }
    },

    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: retail } = retailSlice
export const {
  setItems,
  setPageInfo,
  setSortingValue: setSort,
  clear,
  setLoading,
  setQuery,
  setFilters,
  setError,
  reset,
} = actions
